<template>
  <div class="admin-panel statisctis-table-mobile">
    <b-overlay
      :show="Statistics.is_loading"
      spinner-variant="primary"
      variant="transparent"
      style="min-height: 125px; z-index: 1"
    >
      <template v-for="(item, index) in Statistics.items">
        <b-card no-body class="statistics-card" v-if="!Statistics.is_loading">
          <p class="statistics-card-title">{{ item.offer.title }}</p>
          <div class="d-flex justify-content-end">
            <span class="text-muted mt-auto mb-auto mr-auto">
              Метка 1-го уровня
            </span>
            <span class="text-black align-self-end">
              {{ item.w_utm1 }}
            </span>
          </div>
          <div class="separator"></div>

          <div class="d-flex justify-content-end">
            <span class="text-muted mt-auto mb-auto mr-auto">
              Метка 2-го уровня
            </span>
            <span class="text-black align-self-end">
              {{ item.w_utm2 }}
            </span>
          </div>
          <div class="separator"></div>

          <div class="d-flex justify-content-end">
            <span class="text-muted mt-auto mb-auto mr-auto"> Клики </span>
            <span class="text-black align-self-end">
              {{ item.clicks }}
            </span>
          </div>
          <div class="separator"></div>

          <div class="d-flex justify-content-end">
            <span class="text-muted mt-auto mb-auto mr-auto">
              Уникальные клики
            </span>
            <span class="text-black align-self-end">
              {{ item.uniq_clicks }}
            </span>
          </div>
          <div class="separator"></div>

          <div class="d-flex justify-content-end">
            <span class="text-muted mt-auto mb-auto mr-auto"> eCPC </span>
            <span class="text-black align-self-end">
              {{ item.ecpc ? item.ecpc.formatMoney(2, '', '', '.') : 0 }}
            </span>
          </div>
          <div class="separator"></div>

          <div class="d-flex justify-content-end">
            <span class="text-muted mt-auto mb-auto mr-auto"> Заявки </span>
            <span class="text-black align-self-end">
              {{ item.applications }}
            </span>
          </div>
          <div class="separator"></div>

          <div class="d-flex justify-content-end">
            <span class="text-muted mt-auto mb-auto mr-auto"> CTR </span>
            <span class="text-black align-self-end">
              {{ item.ctr ? parseFloat(item.ctr).formatMoney(2, '', '', '.') : 0.00 }} %
            </span>
          </div>
          <div class="separator"></div>

          <div class="d-flex justify-content-end">
            <span class="text-muted mt-auto mb-auto mr-auto"> Сделки </span>
            <span class="text-black align-self-end">
              {{ item.deals }}
            </span>
          </div>
          <div class="separator"></div>

          <div class="d-flex justify-content-end">
            <span class="text-muted mt-auto mb-auto mr-auto"> AR </span>
            <span class="text-black align-self-end">
              {{ item.ar ? parseFloat(item.ar).formatMoney(2, '', '', '.') : 0 }} %
            </span>
          </div>
          <div class="separator"></div>

          <div class="d-flex justify-content-end">
            <span class="text-muted mt-auto mb-auto mr-auto"> Доход </span>
            <span class="text-black align-self-end">
              {{ item.income.formatMoney('', '', '') + ' ₽' }}
            </span>
          </div>

          <b-collapse v-model="item.collapsed" class="card-collapse">
            <b-tabs pills>
              <b-tab title="Заявки">
                <b-card no-body>
                  <graphic-orders-view :item="item" v-if="item.collapsed" />
                </b-card>
              </b-tab>
              <b-tab title="Клики">
                <b-card no-body>
                  <graphic-clicks-view :item="item" v-if="item.collapsed" />
                </b-card>
              </b-tab>
              <b-tab title="Баланс">
                <b-card no-body>
                  <graphic-balance-view :item="item" v-if="item.collapsed" />
                </b-card>
              </b-tab>
              <b-tab title="CTR">
                <b-card no-body>
                  <graphic-ctr-view :item="item" v-if="item.collapsed" />
                </b-card>
              </b-tab>
              <b-tab title="AR">
                <b-card no-body>
                  <graphic-ar-view :item="item" v-if="item.collapsed" />
                </b-card>
              </b-tab>
            </b-tabs>
          </b-collapse>

          <button
            type="button"
            class="btn"
            :class="{
              'btn-primary': !item.collapsed,
              'btn-outline-primary': item.collapsed,
            }"
            @click="toggleCollapse(item)"
          >
            {{ item.collapsed ? 'Скрыть график' : 'Показать график' }}
          </button>
        </b-card>
      </template>

      <template v-if="!Statistics.is_loading && !Statistics.items.length">
        <div
          class="d-flex flex-column justify-content-center"
          style="padding: 16px"
        >
          <div class="nodata-icon ml-auto mr-auto"></div>
          <p class="text-muted mt-1 ml-auto mr-auto text-center">
            Нет данных для отображения
          </p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import GraphicOrdersView from '../charts/statistics-orders-graph';
import GraphicClicksView from '../charts/statistics-clicks-graph';
import GraphicBalanceView from '../charts/statistics-balance-graph';
import GraphicCtrView from '../charts/statistics-ctr-graph';
import GraphicArView from '../charts/statistics-ar-graph';

export default {
  data() {
    return {
      is_loading: true,
    };
  },
  props: {
    Statistics: Object,
  },
  methods: {
    linkGen(pageNum) {
      return {
        name: this.$route.name,
        query: {
          page: pageNum
        }
      }      
    },
    toggleCollapse(item) {
      if (item.collapsed === null || typeof item.collapsed === 'undefined') {
        this.$set(item, 'collapsed', true);
      } else {
        this.$set(item, 'collapsed', !item.collapsed);
      }
    },
  },
  watch: {
    "$route.query" : function () {
      this.Statistics.list(this.$route.query.page);
    }
  },
  components: {
    GraphicOrdersView,
    GraphicArView,
    GraphicCtrView,
    GraphicBalanceView,
    GraphicClicksView,
  },
};
</script>

<style lang="scss">
.admin-panel.statisctis-table-mobile {
  .statistics-card {
    margin-bottom: 8px !important;
    padding: 24px;

    .statistics-card-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .card-collapse {
      margin-top: 50px;

      .card {
        margin-bottom: unset !important;
      }
    }
    .separator {
      margin: 16px 0;
    }
    .btn {
      margin-top: 20px;
    }
    .tab-content {
      margin-left: -25px;
    }
    .nav-pills {
      margin-bottom: 20px;
    }
  }
}
</style>
