

    import $request from "@/modules/request/"

    export default {

        items: [],
        utms: {
            w_utm1: [],
            w_utm2: [],
        },
        last_page: 0,
        total_count: 0,
        current_page: 0,
        has_more_pages: false,
       
        filters: {
            user: '',
            select: 'today',
            period: {
                start: '',
                end: ''
            },
            product: null,
            company: null,
            w_utm1: null,
            w_utm2: null,
        },

        selected_user: null,

        o_start: '',
        o_end: '',

        is_loading: false,

        list(page = 1, cb = null) {
            this.is_loading = true;
            this.filters.page = page;

            $request.get("statistics/list", this.filters ).then( rsp => {
                this.items = rsp.items;
                this.last_page = rsp.last_page;
                this.total_count = rsp.total_count;
                this.current_page = rsp.current_page;
                this.has_more_pages = rsp.has_more_pages;

                this.o_start = rsp.o_start;
                this.o_end = rsp.o_end;

                if (cb) cb();

                this.is_loading = false;
            });
        },

        getUtm() {
            $request.get("statistics/getUtm", this.filters ).then( rsp => {
                this.utms = rsp;
            });
        }

       
    }
