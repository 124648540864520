<template>
  <div class="statistics">
    <div class="d-flex justify-content-between mb-2">
      <p class="d-flex align-items-end main-header-section">
        <span>Статистика 
          <span
            v-if="
              !Statistics.is_loading &&
              Statistics.filters.select !== 'today' &&
              Statistics.filters.select !== 'yesterday' &&
              Statistics.o_start.length > 0 &&
              Statistics.o_end.length > 0"
          >
          с {{ Statistics.o_start }} по {{ Statistics.o_end }}
          </span>
        </span>
        <b-badge
          class="ml-1"
          :variant="Statistics.filters.user.length > 0 ? 'warning' : 'success'"
          style="
            border-radius: 50px;
            padding: 4px 8px 6px 8px;
            font-weight: 400;
          "
        >
          {{ Statistics.filters.user.length > 0 ? "Пользователь" : "Платформа" }}
        </b-badge>
      </p>
      <div class="download-outline-btn" @click="exportExcel">
        <feather-icon icon="DownloadIcon" style="margin-right: 8px" size="18" />
        Сохранить отчет
      </div>
    </div>

    <filters-view />

    <table-view :Statistics="Statistics" />
  </div>
</template>

<script>
import User from "@/modules/user/"

import Statistics from "@/modules/admin/statistics";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import tableView from "../tables/desktop";
import FiltersView from "../widgets/filters";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      Statistics,
    };
  },
  beforeRouteEnter(from, to, next ) {
            
            let perms = User.self.permissions.find(item => item.registry.key === 'statistics' );
                        
            if( !perms ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'statistics'
                    }
                });
            }
            
            if( perms.read === false ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'statistics'
                    }
                });
            }
            
            next( true );
            
        },
  props: {},
  methods: {
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
    loadMore() {},
    changeActiveCategories(categories) {},
    exportExcel() {
      if (Statistics.items.length > 0) {
        this.$request
          .export("export/m/statistics", Statistics.filters)
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let fileName = "статистика";
            if (Statistics.o_start && Statistics.o_start.length > 0)
              fileName += "_c_" + Statistics.o_start;
            if (Statistics.o_end && Statistics.o_end.length > 0)
              fileName += "_по_" + Statistics.o_end;
            fileName += ".xlsx";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            text: "Нет данных для для отчета.",
            icon: "CheckCircleIcon",
            variant: "warning",
          },
        });
      }
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    FiltersView,
    tableView,
  },

  computed: {},

  watch: {},
  mounted() {
    this.$router.replace({ query: {} });
    Statistics.list();
  },
};
</script>

<style lang="scss"></style>
