<template>
  <div class="statistics">
    <div class="d-flex justify-content-between mb-2">
      <p class="main-header-section">
        <span class="d-block"> Статистика</span>
        <span
          v-if="
            !Statistics.is_loading &&
            Statistics.filters.select !== 'today' &&
            Statistics.filters.select !== 'yesterday'
          "
        >
          с {{ Statistics.o_start }} по {{ Statistics.o_end }}
        </span>
      </p>
      <div class="download-outline-btn">
        <feather-icon icon="DownloadIcon" style="margin-right: 8px" size="18" />
        <span>Сохранить отчет</span>
      </div>
    </div>

    <filters-view />

    <table-view :Statistics="Statistics" />
  </div>
</template>

<script>
import Statistics from "@/modules/admin/statistics";
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import tableView from '../tables/mobile';
import FiltersView from '../widgets/filters';

export default {
  data() {
    return {
      Statistics,
    };
  },
  props: {},
  methods: {
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
    loadMore() {},
    changeActiveCategories(categories) {},
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    FiltersView,
    tableView,
  },

  computed: {},

  watch: {},
  mounted() {
    this.$router.replace({ query: {} })
    Statistics.list();
  },
};
</script>
